import React from "react"

import { Link } from "gatsby"
import { FaChevronRight } from "react-icons/fa"

export default ({
  url = "/",
  text = "Home",
  classNames = "",
  color = "primary",
}) => {
  // combine classes before inserting
  return (
    <Link
      className={`flex text-white font-bold font-heading ${classNames}`}
      to={url}
    >
      {/* > Needs to be replaced with SVG */}
      <span
        className={`flex ${
          color === "primary"
            ? "bg-primary-dark"
            : color === "secondary"
            ? "bg-secondary-dark"
            : color === "tertiary"
            ? "bg-tertiary-dark"
            : "bg-primary-dark"
        } p-4 items-center`}
      >
        <FaChevronRight className="text-2xl" />
      </span>
      <span
        className={`${
          color === "primary"
            ? "bg-primary-light"
            : color === "secondary"
            ? "bg-secondary-light"
            : color === "tertiary"
            ? "bg-tertiary-light"
            : "bg-primary-light"
        } p-4 uppercase tracking-wide`}
      >
        {text}
      </span>
    </Link>
  )
}
