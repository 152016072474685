import { graphql, Link } from "gatsby"
import React from "react"
import ImageGallery from "react-image-gallery"
import { mySanityClient } from "../../sanityClient"
import urlBuilder from "@sanity/image-url"

import Layout from "../../components/layout"
import ButtonLink from "../../components/ButtonLink"
import PhotoAlbum from "../../components/albums/PhotoAlbum"

const Album = ({ data }) => {
  const { title, rawAlbumImages, description } = data.sanityAlbums

  // Common error: make sure @sanity/client is installed
  const builder = urlBuilder(mySanityClient)
  // Get the url for a given source
  const urlFor = source => builder.image(source)

  // create an array of urls for the album
  const imageUrlArray = rawAlbumImages.map(imageData => {
    const url = urlFor(imageData.asset.id)
      .width(2000)
      .format("jpg")
      .fit("max")
      .url()

    const previewUrl = urlFor(imageData.asset.id)
      .width(200)
      .height(200)
      .format("jpg")
      .url()

    // const previewUrl = url + "?h=200" + "&w=200" + "&fm=jpg"

    const altText = imageData.altText || ""
    let captionText = imageData.caption || imageData.imageTitle || ""
    // Add credit to caption
    if (imageData.imageCredit) {
      captionText = captionText.concat(", Credit: ", imageData.imageCredit)
    }

    return {
      original: url,
      originalAlt:
        altText || imageData.caption || imageData.asset.originalFilename || "",
      thumbnail: previewUrl,
      thumbnailAlt:
        altText || imageData.caption || imageData.asset.originalFilename || "",
      description: captionText,
    }
  })

  return (
    <Layout>
      <div className="mx-10  mt-20 lg:mt-32 mb-20 grid justify-items-center ">
        {/* Top Matter */}
        <section className="w-4/5 lg:w-2/3">
          <h1 className="text-center">{title}</h1>
          {description && <p>{description}</p>}
        </section>
        {/* Gallery */}

        <PhotoAlbum
          imageUrlArray={imageUrlArray}
          className="max-w-xs md:max-w-xl bg-gray-light w-screen"
        />
        <ButtonLink
          url="/media"
          className="uppercase"
          text="All Albums"
          color="primary"
        >
          View All Albums
        </ButtonLink>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityAlbums(id: { eq: $id }) {
      title
      description
      rawAlbumImages: _rawAlbumImages(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default Album
